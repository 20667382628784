<template>
  <OsScroller
    class="badge-navigation"
    :class="{
      'badge-navigation--spaced': spaced,
      'badge-navigation--wrapped': wrapped
    }"
    :page-margin="pageMargin"
  >
    <ul>
      <li v-for="(item, index) in items" :key="`badge-navigation_${index}`">
        <button @click="badgeClicked(item)">
          <BadgeItem
            :item="item"
            :classes="item.classes"
            v-bind="badgeProps"
            :is-active="!!modelValue && modelValue === item[itemValue]"
          >
            <slot :item="item" />
          </BadgeItem>
        </button>
      </li>
      <li v-if="$slots.suffix">
        <slot name="suffix" />
      </li>
    </ul>
  </OsScroller>
</template>

<script lang="ts">
import { BadgeKey } from '~/components/badge/Item.vue'
import { Badge } from '~/types/components'

export default {
  props: {
    modelValue: {
      type: [String, Number],
      required: false,
      default: null
    },
    itemValue: {
      type: String,
      required: false,
      default: 'id'
    },
    items: {
      type: Array as PropType<Badge[]>,
      required: true
    },
    badgeProps: {
      type: Object,
      required: false,
      default: () => ({})
    },
    pageMargin: {
      type: Number,
      required: false,
      default: undefined
    },
    spaced: {
      type: Boolean,
      required: false,
      default: false
    },
    wrapped: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  emits: ['item-selected', 'update:modelValue'],

  setup(props, { emit }) {
    provide(BadgeKey, {
      hasDash: false
    })

    const badgeClicked = (item: Badge) => {
      emit('item-selected', item)

      if (props.modelValue && props.modelValue === item[props.itemValue]) return

      emit('update:modelValue', item[props.itemValue])
    }

    return {
      badgeClicked
    }
  }
}
</script>

<style lang="scss" scoped>
.badge-navigation {
  button {
    cursor: pointer;
    outline-offset: -3px;
    border-radius: 20px;
    &:focus {
      &:deep(.badge.badge--active) {
        outline-offset: 4px;
        outline: solid 2px $blue;
      }
    }
  }
}

ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  grid-gap: rem(8);
  padding: 0;
  width: fit-content;
  list-style: none;

  li {
    cursor: pointer;
    white-space: nowrap;

    button {
      padding: 0;
    }
  }

  .badge-navigation--spaced & {
    grid-gap: rem(16);
  }
  .badge-navigation--wrapped & {
    flex-wrap: wrap;
  }
}
</style>
